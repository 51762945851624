@import "@wetransfer/transfer/stylesheets/mixin/aspect-ratio.scss";
@import "@wetransfer/transfer/stylesheets/mixin/button.scss";
@import "@wetransfer/transfer/stylesheets/mixin/layout.scss";
@import "@wetransfer/transfer/stylesheets/mixin/margins.scss";
@import "@wetransfer/transfer/stylesheets/mixin/media.scss";
@import "@wetransfer/transfer/stylesheets/mixin/panel.scss";
@import "@wetransfer/transfer/stylesheets/mixin/typography.scss";
@import "@wetransfer/transfer/spaces-bundle/styles/constants.scss";
.ErrorState {
  position: relative;
  z-index: 0;
  background: #FFF;
  display: flex;
  justify-content: center;
  justify-items: center;
}

.Logo {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 52px;
  height: 29px;
}

.Error {
  margin: auto;
  color: #F00;
}

.Wrapper {
  max-width: 60%;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Spiral {
  max-height: 40%;
  margin-bottom: 2em;
}

.Title {
  margin: 1em 0 0.2em 0;
  font-size: 1em;
}

.Description {
  font-size: 1em;
  line-height: 1.25em;
  a {
    color: #000;

    &:hover {
      color: #5268ff;
    }
  }
}

.Loader {
  margin: auto;
  display: block;
  height: 31px;
  width: 65px;
  animation: loading-pulse 3.5s infinite;
}

@keyframes loading-pulse {
  from {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
